import httpClient from '@qualtrics/page-http-client';

const HTTP_CLIENT_SETTINGS = {
  credentials: 'omit',
  applyXsrfHeader: false,
  applyTransactionId: false,
};
const TRANSLATIONS_BASE_URL = '/static/pf-translations';

const fetchSingleSectionTranslations = async (language, version) => {
  const sectionResponse = await httpClient.cachedGet(
    `${TRANSLATIONS_BASE_URL}/${language}/${version}.json`,
    HTTP_CLIENT_SETTINGS,
  );
  return sectionResponse.data;
};

export const loadTranslations = async (language, sections, versions) => {
  if (!versions) {
    return null;
  }
  return Promise.all(
    sections.map((sectionName) => {
      return fetchSingleSectionTranslations(language, versions[sectionName]);
    }),
  )
    .then((fetchedSections) => {
      return fetchedSections.reduce(
        (translations, current) => ({ ...translations, ...current }),
        {},
      );
    })
    .catch(() => {
      return null;
    });
};
